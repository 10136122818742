<template>
  <r-page>
    <template #page-header>
      <h2 class="page-title">Admin Payment Management</h2>
    </template>
    <template #action-bar>
      <div class="level action-bar-padding">
        <div class="level-left">
          <b-button
            type="is-primary"
            icon-left="plus"
            label="Add Admin"
            @click.native="openModalAdd()"
          ></b-button>
        </div>
        <div class="level-right">
          <b-input
            icon-right="magnify"
            v-model="search"
            placeholder="Search"
            @keydown.native.enter="searchIconClick(search)"
            icon-clickable
            @icon-right-click="searchIconClick(search)"
            icon-right-clickable
            :loading="isLoading"
          ></b-input>
        </div>
      </div>
    </template>
    <template #page-content>
      <b-table
        :data="data ? data.data : []"
        :loading="isLoading"
        paginated
        backend-sorting
        backend-pagination
        :total="data && data.meta ? data.meta.total : {}"
        :per-page="perPage"
        :default-sort-direction="sortOrder"
        :default-sort="[sortField, sortOrder]"
        @sort="onSort"
        @page-change="onPageChange"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page"
        :current-page.sync="page"
        class="table-admin-payment-management"
      >
        <b-table-column
          field="id"
          label="No"
          v-slot="props"
          width="3%"
          sortable
        >
          <span>{{ props.index + data.meta.from }}</span>
        </b-table-column>
        <b-table-column
          field="email"
          label="Email"
          width="30%"
          v-slot="props"
          sortable
        >
          <span>{{ props.row.email }}</span>
        </b-table-column>
        <b-table-column
          field="role"
          label="Role Access"
          v-slot="props"
          width="25%"
          sortable
        >
          <span class="admin-role">
            {{
              props.row.role && props.row.role === 'ADMIN'
                ? 'Admin'
                : 'Super Admin'
            }}
          </span>
        </b-table-column>
        <b-table-column
          class="table-is-active"
          field="status"
          label="Status"
          width="25%"
          v-slot="props"
          sortable
        >
          <span
            class="is-capitalize table-is-active active"
            v-if="props.row.status === 1"
          >
            Active
          </span>
          <span
            class="is-capitalize table-is-active inactive"
            v-if="props.row.status === 0"
          >
            Inactive
          </span>
        </b-table-column>
        <b-table-column
          field="action"
          label="Action"
          width="10%"
          v-slot="props"
        >
          <b-icon
            icon="pencil"
            custom-size="mdi-18px"
            style="cursor: pointer"
            @click.native="openModalEdit(props.row)"
          ></b-icon>
          <b-icon
            icon="delete"
            custom-size="mdi-18px"
            style="cursor: pointer; color: #cb3a31"
            @click.native="openDelete(props.row.id)"
          ></b-icon>
        </b-table-column>
        <template slot="empty">
          <section class="section">
            <div class="content has-text-grey has-text-centered">
              <p>
                No data yet, please contact us if you have any problems
                (hello@getrise.id)
              </p>
            </div>
          </section>
        </template>
      </b-table>

      <!-- ADD MODAL -->
      <r-page-modal
        :prompt-on-leave="false"
        prompt-mesage="Are you sure?"
        v-if="isModalAddOpen"
        :is-modal="true"
        @hide="closeModal"
        key="modalKey"
        remove-scrolling
      >
        <template #page-content>
          <h4 class="admin-modal-title">
            Add Admin
            <span class="pointer-click is-pulled-right">
              <b-icon
                icon="close"
                size="is-small"
                @click.native="closeModal"
              ></b-icon>
            </span>
          </h4>
          <validation-provider name="name" v-slot="{ errors }" rules="required">
            <b-field
              label="Name"
              :type="errors[0] && 'is-danger'"
              :message="errors[0]"
              class="pb-4"
            >
              <b-input v-model="selected.name" placeholder="Enter name" />
            </b-field>
          </validation-provider>
          <validation-provider
            name="email"
            v-slot="{ errors }"
            rules="required|email"
          >
            <b-field
              label="Email"
              :type="errors[0] && 'is-danger'"
              :message="errors[0]"
              class="pb-4"
            >
              <b-input v-model="selected.email" placeholder="Enter email" />
            </b-field>
          </validation-provider>
          <b-field label="Role">
            <b-select v-model="selected.role" expanded class="pb-4">
              <option value="ADMIN">Admin</option>
            </b-select>
          </b-field>
          <b-field label="Status">
            <b-switch
              v-model="selected.status"
              true-value="1"
              false-value="0"
              type="is-success"
            >
              {{ selected.status ? 'Active' : 'Inactive' }}
            </b-switch>
          </b-field>
          <div class="columns is-multiline">
            <div class="column is-6">
              <b-button
                expanded
                type="is-primary"
                outlined
                @click.native="closeModal()"
              >
                Cancel
              </b-button>
            </div>
            <div class="column is-6">
              <b-button expanded @click.native="submitData">Add</b-button>
            </div>
          </div>
        </template>
      </r-page-modal>

      <!-- EDIT MODAL -->
      <r-page-modal
        :prompt-on-leave="false"
        prompt-message="Are you sure?"
        v-if="isModalEditOpen"
        :is-modal="true"
        @hide="closeModal"
        key="modalKey"
        remove-scrolling
      >
        <template #page-content>
          <h4 class="admin-modal-title">
            Edit Admin
            <span class="pointer-click is-pulled-right">
              <b-icon
                icon="close"
                size="is-small"
                @click.native="closeModal"
              ></b-icon>
            </span>
          </h4>
          <validation-provider name="name" v-slot="{ errors }" rules="required">
            <b-field
              label="Name"
              :type="errors[0] && 'is-danger'"
              :message="errors[0]"
            >
              <b-input
                class="pb-4"
                v-model="selected.name"
                placeholder="Enter name"
              />
            </b-field>
          </validation-provider>
          <b-field label="Role">
            <b-select v-model="selected.role" expanded class="pb-4">
              <option value="ADMIN">Admin</option>
            </b-select>
          </b-field>
          <b-field label="Status">
            <b-switch
              v-model="selected.status"
              true-value="1"
              false-value="0"
              type="is-success"
            >
              {{ selected.status ? 'Active' : 'Inactive' }}
            </b-switch>
          </b-field>
          <div class="columns is-multiline">
            <div class="column is-6">
              <b-button
                expanded
                type="is-primary"
                outlined
                @click.native="closeModal()"
              >
                Cancel
              </b-button>
            </div>
            <div class="column is-6">
              <b-button expanded @click.native="updateData">Save</b-button>
            </div>
          </div>
        </template>
      </r-page-modal>
      <warning-modal
        :isModalOpen="isDeleteOpen"
        :title="'Are you sure?'"
        :subtitle="'are you sure you want to delete this?'"
        @close="closeModalDelete"
        @run="deleteRecord(selected.id)"
      />
    </template>
  </r-page>
</template>
<script>
import WarningModal from '../components/WarningModal.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { showToast } from '@/services/util'
export default {
  components: { WarningModal },
  data() {
    return {
      isModalAddOpen: false,
      isModalEditOpen: false,
      search: null,
      data: {
        meta: {},
        data: [],
      },
      selected: {
        id: null,
        name: '',
        email: '',
        role: 'ADMIN',
        status: 0,
      },
      perPage: 10,
      page: 1,
      lastPage: 1,
      total: 0,
      sortField: 'id',
      sortOrder: 'desc',
      isLoading: false,
      isEditing: false,
      name: null,
      email: null,
      role: 'ADMIN',
      isDeleteOpen: false,
    }
  },
  async mounted() {
    this.setAdminIndex([])
    await this.loadAdminIndex()
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
      getAdmin: 'admin/getAdminIndex',
    }),
  },
  methods: {
    ...mapActions({
      fetchAdminIndex: 'admin/fetchAdminIndex',
      saveAdmin: 'admin/saveAdmin',
      updateAdmin: 'admin/updateAdmin',
      deleteAdmin: 'admin/deleteAdmin',
    }),
    ...mapMutations({
      setAdminIndex: 'admin/setAdminIndex',
    }),
    openModalAdd() {
      this.isModalAddOpen = true
    },
    async openModalEdit(row) {
      if (row) {
        this.isEditing = true
        this.selected = {
          id: row.id,
          name: row.name,
          email: row.email,
          role: row.role,
          status: row.status,
        }
      }
      this.isModalEditOpen = true
    },
    closeModal() {
      this.isModalAddOpen = false
      this.isModalEditOpen = false
      this.isEditing = false
      this.selected = {
        name: '',
        email: '',
        role: 'ADMIN',
        status: 0,
      }
    },
    closeModalDelete() {
      this.isDeleteOpen = false
    },
    openDelete(id) {
      this.selected.id = id
      this.isDeleteOpen = true
    },
    async deleteRecord() {
      await this.deleteAdmin(this.selected.id)
        .then(() => {
          showToast('Record Successfully Deleted!', 'is-success', 'is-top')
          this.isDeleteOpen = false
          this.setAdminIndex([])
          this.loadAdminIndex()
        })
        .catch((e) => {
          this.isDeleteOpen = false
          showToast(e.response.data.message, 'is-danger', 'is-top')
        })
    },

    resetState(searchInput) {
      this.page = 1
      this.lastPage = 1
      this.setAdminIndex([])
      this.loadAdminIndex(searchInput)
    },

    onPageChange(page) {
      this.page = page
      this.loadAdminIndex()
    },

    onSort(field, order) {
      this.sortField = field
      this.sortOrder = order
      this.resetState()
    },

    async searchIconClick(searchInput) {
      await this.resetState(searchInput)
    },

    async loadAdminIndex(searchInput) {
      try {
        const response = await this.fetchAdminIndex({
          perPage: this.perPage,
          page: this.page,
          sortField: this.sortField,
          sortOrder: this.sortOrder,
          search: searchInput,
        })
        this.data = response.data
      } catch (e) {
        console.log(e)
      }
    },

    async submitData() {
      this.isLoading = true
      try {
        let response = await this.saveAdmin({
          name: this.selected.name,
          email: this.selected.email,
          role: this.selected.role,
          status: this.selected.status,
        })
        if (response && response.status === 201) {
          this.isLoading = false
          this.closeModal()
          this.setAdminIndex([])
          await this.loadAdminIndex()
          showToast('Add Success', 'is-success', 'is-top')
        }
      } catch (e) {
        showToast(e.response.data.message, 'is-danger', 'is-top')
        this.isLoading = false
      }
    },
    async updateData() {
      this.isLoading = true
      try {
        let response = await this.updateAdmin({
          id: this.selected.id,
          form: this.selected,
        })
        if (response && response.status === 200) {
          this.isLoading = false
          this.closeModal()
          this.setAdminIndex([])
          await this.loadAdminIndex()
          showToast('Edit Success', 'is-success', 'is-top')
        }
      } catch (e) {
        this.isLoading = false
        showToast(e.response.data.message, 'is-danger', 'is-top')
      }
    },
  },
}
</script>
